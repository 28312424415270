<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de mis visitas - Análisis</h4>
            <div class="small text-muted">Consultar análisis de mis visitas.</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>                            
              <b-col sm="2">
                <v-select :options="arr.select.zones" v-model="filter.zones" placeholder="Zonas" :multiple="true" :select-on-tab="true"></v-select>
              </b-col> 
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                class = 'pull-right mt-3'
                :data = "arr.export"
                worksheet = "Informe de Visitas"
                name = "reports-visits.xls">
                Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    v-if="table.items.length">
                
                <template v-slot:row-details="row">
                  <b-card v-if="row.item.zone">
                    <b-row>
                      <b-col md="12">                  
                        <b class="mb-1"><u>Dentro del período</u></b>      
                        <div v-if="row.item.detail.length"  class="mt-1 ml-2">                          
                          <div v-for="(element, index) in row.item.detail" v-bind:key="index">                            
                            <b>ID: </b> {{element.id}} - 
                            <b>Fecha: </b> {{moment(element.date).format('DD MMMM YYYY')}} -
                            <b>Cliente: </b> 
                            <b-icon icon="circle-fill" v-if="!element.customer.active" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" /> 
                            {{element.customer.name}} -                            
                            <span v-if="element.buy">
                              <span class="text-success"><b>Compró</b></span> 
                            </span>
                            <span v-else>
                              <span class="text-danger"><b>No Compró</b></span> 
                            </span>
                          </div>
                        </div>
                        <div v-else class="mt-1">
                          <b-alert show variant="warning">No se encontraron registros</b-alert>
                        </div>

                        <hr>
                        
                        <b><u>Dentro de los 7 días posteriores</u></b>
                        <div v-if="row.item.detail_extra.length" class="mt-1 ml-2">
                          <div v-for="(element, index) in row.item.detail_extra" v-bind:key="index">
                            <b>ID: </b> {{element.id}} - 
                            <b>Fecha: </b> {{moment(element.date).format('DD MMMM YYYY')}} -
                            <b>Cliente: </b> 
                            <b-icon icon="circle-fill" v-if="!element.customer.active" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" /> 
                            {{element.customer.name}} -                            
                            <span v-if="element.buy">
                              <span class="text-success"><b>Compró</b></span> 
                            </span>
                            <span v-else>
                              <span class="text-danger"><b>No Compró</b></span> 
                            </span>
                          </div>
                        </div>
                        <div v-else class="mt-1">
                          <b-alert show variant="warning">No se encontraron registros</b-alert>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>

                <template v-slot:cell(date_start)="data">
                  {{ moment(data.item.date_start).format('DD MMMM YYYY') }}
                </template>                

                <template v-slot:cell(date_end)="data">
                  {{ moment(data.item.date_end).format('DD MMMM YYYY') }}
                </template>                
                
                <template v-slot:cell(assigned)="data"> 
                  <div v-if="data.item.zone" class="report-visit-analysis-color-zone">
                    {{data.item.zone.name}}
                  </div>
                  <div v-else :title="data.item.observation">{{data.item.activity}}</div>
                </template>

                <template v-slot:cell(visited)="data">   
                  <div v-if="data.item.zone">                
                    <b-badge class="report-visit-analysis-percentage">
                      {{getVisitedByCalendar(data.item,'percentage')}} %
                    </b-badge>
                    <div>
                      <small class="report-visit-analysis-tag">
                        <font color="blue">({{getVisitedByCalendar(data.item,'count')}} de {{data.item.zone.customers.length}} clientes)</font>
                      </small>                  
                    </div>
                  </div>
                </template>
                
                <template v-slot:cell(sales)="data"> 
                  <div v-if="data.item.zone">
                    <b-badge class="report-visit-analysis-percentage">
                      {{getSalesByCalendar(data.item,'percentage')}} %
                    </b-badge>
                    <div>
                      <small class="report-visit-analysis-tag">
                        <font color="blue">({{getSalesByCalendar(data.item,'count')}} de {{data.item.zone.customers.length}} clientes)</font>
                      </small>                  
                    </div>
                  </div>
                </template>
                
                <template v-slot:cell(evaluation)="data"> 
                  <div v-if="data.item.zone">
                    <div class="report-visit-analysis-evaluation" v-if="getSalesByCalendar(data.item,'percentage') >= 60">
                      <i class="material-icons">trending_up</i>
                    </div>
                    <div class="report-visit-analysis-evaluation" v-if="getSalesByCalendar(data.item,'percentage') > 40 && getSalesByCalendar(data.item,'percentage') < 60">
                      <i class="material-icons">trending_flat</i>
                    </div>
                    <div class="report-visit-analysis-evaluation" v-if="getSalesByCalendar(data.item,'percentage') <= 40">
                      <i class="material-icons">trending_down</i>
                    </div>
                    <div v-if="data.item.zone.customers.length==0">
                      <font color="red">ERROR EN ZONA</font>
                    </div>
                  </div>
                </template>
                
                <template v-slot:cell(effectiveness)="data"> 
                  <div v-if="data.item.zone">
                    <b-badge class="report-visit-analysis-percentage">
                      {{getEffectivenessByCalendar(data.item)}} %
                    </b-badge>
                    <div>
                      <small class="report-visit-analysis-tag">
                        <font color="blue">({{getSalesByCalendar(data.item,'count')}} de {{getVisitedByCalendar(data.item,'count')}} visitados)</font>
                      </small>                  
                    </div>
                  </div>
                </template>
                
              </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Vue from 'vue'
  import excel from 'vue-excel-export'

  Vue.use(excel)

  export default {  
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_VISITAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'queryAnalysisSeller',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [                        
            {key: 'date_start', label: 'Fecha Inicio', class: "align-middle"},
            {key: 'date_end', label: 'Fecha Fin', class: "align-middle"},          
            {key: 'assigned', label: 'Asignado', class: "align-middle"},
            {key: 'visited', label: 'Visitados', class: "align-middle text-center"},            
            {key: 'sales', label: 'Ventas', class: "align-middle text-center"},            
            {key: 'evaluation', label: 'Evaluación', class: "align-middle text-center"},            
            {key: 'effectiveness', label: 'Efectividad', class: "align-middle text-center"},             
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 100,
        },
        arr: {
          export: [],
          calendar: [],
          select : {            
            zones: []
          },
        },
        filter: {
          date_start: '',
          date_end: '',
          zones: [],
          sellers: null,
        },     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.filterLoad()
      this.filterReport()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {              
        this.arr.calendar.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterReport()
      }, 

      filterLoad() {         
        this.filter.sellers = {
          code: Helper.getSeller().id,
          name: Helper.getSeller().name,
        }
        this.filterLoadZones()   
        this.filter.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 
      },      
      filterLoadZones() {   
        this.arr.select.zones= []
        this.filter.zones = []

        if(this.filter.sellers) {
          var result = serviceAPI.obtenerZonasByVendedor({sellers_id: this.filter.sellers.code})        
          result.then((response) => {
            var data = response.data

            this.arr.select.zones = []
            data.forEach(element => {                            
              this.arr.select.zones.push({ code: element.id, label: element.name })            
            });
          }) 
        }                         
      },          
      filterReport(){
        let loader = this.$loading.show()   
              
        var result = serviceAPI.filtrarAnalisis(this.filter)        
        result.then((response) => {
          loader.hide()
          var data = response.data
          this.table.items = data.calendar
          this.arr.calendar = data.calendar

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }       

          this.prepareExport(data.calendar)
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });      
      }, 
      prepareExport(data) {
        data.forEach(element => {
          var zoneID = 0
          var zoneName = ''
          if(element.zone) {
            zoneID = element.zone.id
            zoneName = element.zone.name
          }

          element.detail.forEach(subElement1 => {
            this.arr.export.push({
              calendarID: element.id,              
              dateStart: element.date_start,
              dateEnd: element.date_end,
              zoneID: zoneID,
              zoneName: zoneName,
              activity: element.activity,  
              
              reportVisitStatus: "Dentro del período",
              reportVisitID: subElement1.id,
              reportVisitDate: subElement1.date,
              reportVisitCustomersID: subElement1.customer.id,
              reportVisitCustomersCode: subElement1.customer.code,
              reportVisitCustomersName: subElement1.customer.name,              
            })
          })
                    
          element.detail_extra.forEach(subElement2 => {
            this.arr.export.push({
              calendarID: element.id,
              dateStart: element.date_start,
              dateEnd: element.date_end,
              zoneID: zoneID,
              zoneName: zoneName,
              activity: element.activity,  
              
              reportVisitStatus: "Dentro de los 7 días posteriores",
              reportVisitID: subElement2.id,
              reportVisitDate: subElement2.date,
              reportVisitCustomersID: subElement2.customer.id,
              reportVisitCustomersCode: subElement2.customer.code,
              reportVisitCustomersName: subElement2.customer.name,              
            })
          })                
        });
      },    

      getVisitedByCalendar(item, type) {
        var visited = item.detail.length + item.detail_extra.length
        var percentage = 0
        
        if(item.zone.customers.length) {
          percentage = (visited / item.zone.customers.length) * 100
        }

        if(type=='percentage'){
          return percentage.toFixed(2)
        } else {
          return visited
        }
      },

      getSalesByCalendar(item, type) {
        var percentage = 0
        var sales = 0

        item.detail.forEach(element => {
          if(element.buy) {
            sales = sales + 1
          }
        })

        item.detail_extra.forEach(element => {
          if(element.buy) {
            sales = sales + 1
          }
        })              
        
        if(item.zone.customers.length) {
          percentage = (sales / item.zone.customers.length) * 100
        }

        if(type=='percentage'){
          return percentage.toFixed(2)
        } else {
          return sales
        }
      },

      getEffectivenessByCalendar(item) {
        var percentage = 0        
        
        var visited = this.getVisitedByCalendar(item, 'count')
        var sales = this.getSalesByCalendar(item, 'count')

        if(visited) {
          percentage = (sales / visited) * 100
        }
      
        return percentage.toFixed(2)      
      }

    }    
  }
</script>
<style>
  .report-visit-analysis-color-zone {
    color: darkred;
    font-weight: 600;
  }
  .report-visit-analysis-tag {
    font-size: 12px;
    font-weight: 700;
  }
  .report-visit-analysis-percentage{
    font-size: 15px;
    font-weight: 700;
  }  
  .report-visit-analysis-evaluation i {
    font-size: 30px;
  }  
</style>